import { ComposableFunctionArgs } from '~/composables';
import { Logger } from '~/helpers/logger';
import { AttachToCartParams } from '~/modules/account/composables/useGuestUser';
import { SetGuestEmailOnCartInput } from '~/modules/GraphQL/types';
import { UseContextReturn } from '~/types/core';

export const attachToCartCommand = {
  execute: async (context: UseContextReturn, params: ComposableFunctionArgs<AttachToCartParams>) => {
    Logger.debug('[Magento]: Attach guest cart to user');

    const emailOnCartInput: SetGuestEmailOnCartInput = {
      email: params?.email,
      cart_id: params?.cart?.value?.id,
    };

    const { data, errors } = await context.app.$vsf.$magento.api.setGuestEmailOnCart({
      ...emailOnCartInput,
    }, params?.customQuery ?? null, params?.customHeaders);
    console.log('data setGuestEmailOnCart', data);
    console.log('errors setGuestEmailOnCart', errors);
    return { data, error: errors?.length > 0 && errors[0].message };
  },
};
