import type { Context } from '@nuxt/types';
import { LocaleObject } from 'nuxt-i18n';

// const findLocaleBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>) => locales.find((locale) => ((typeof locale === 'object' ? locale.code : locale) === storeCode));
const findStoreCodeByLocale = (localeString: string, locales: Array<string | LocaleObject>) => {
  const rightLocalConfig = locales.find((localeItem) => (typeof localeItem === 'object' ? localeItem.code : localeItem) === localeString);
  if (rightLocalConfig && typeof rightLocalConfig === 'object') {
    return rightLocalConfig.storeCode;
  }
  return 'default';
};

const findCurrencyBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>): string => {
  const match = locales.find((locale) => typeof locale === 'object' && locale.code === storeCode) as LocaleObject | undefined;
  return match?.defaultCurrency;
};

export default ({ app, route }: Context) => {
  app.$vsf.$magento.client.interceptors.request.use(async (request) => {
    const {
      $vsf: {
        $magento: {
          config: { state },
        },
      },
      i18n,
    } = app;

    // const currentStoreCode = state.getStore() ?? route.path.split('/')[1]; // localhost:3000/default

    const i18nCurrentLocaleCode = i18n.locale; // nuxt.config.js-> i18n -> defaultLocale

    const currentStoreCode = state.getStore() ?? findStoreCodeByLocale(i18nCurrentLocaleCode, i18n.locales);

    // const shouldSetDefaultLocale = !currentStoreCode || !findLocaleBasedOnMagentoStoreCode(currentStoreCode, i18n.locales);

    const shouldSetDefaultLocale = i18n.locales.findIndex((item) => item.code === i18nCurrentLocaleCode) < 0;

    if (shouldSetDefaultLocale) {
      await i18n.setLocale(i18n.defaultLocale);
    }

    const shouldLocaleBeRefreshed = i18nCurrentLocaleCode !== state.getLocale();

    if (shouldLocaleBeRefreshed) {
      const currency = findCurrencyBasedOnMagentoStoreCode(i18nCurrentLocaleCode, i18n.locales);

      // const i18nStoreCode = (i18nCurrentLocaleCode === 'cn' || i18nCurrentLocaleCode === 'gb') ? 'default' : 'uus_2';
      const i18nStoreCode = currentStoreCode;

      state.setStore(i18nStoreCode);
      state.setLocale(i18nCurrentLocaleCode);
      state.setCurrency(currency);
    }
    if (!state.getStore()) {
      const currency = findCurrencyBasedOnMagentoStoreCode(i18nCurrentLocaleCode, i18n.locales);
      state.setStore(currentStoreCode);
      state.setCurrency(currency);
      state.setLocale(i18nCurrentLocaleCode);
      request.headers.cookie += `;vsf-store=${currentStoreCode};vsf-locale=${i18nCurrentLocaleCode};vsf-currency=${currency}`;
    }
    if (process.client) {
      // // 获取链接中的current参数
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const params = new URLSearchParams(url.search);
      const urlCurrency = params.get('currency'); // 假设你想获取名为 param1 的参数
      if (urlCurrency) {
        state.setCurrency(urlCurrency);
        request.headers.cookie += `;vsf-store=${currentStoreCode};vsf-locale=${i18nCurrentLocaleCode};vsf-currency=${urlCurrency}`;
        // 清空查询参数
        url.search = '';
        window.history.replaceState({}, document.title, url.toString());
        // 刷新页面
        window.location.reload();
      }
    }

    return request;
  });
  // 添加响应拦截器
  app.$vsf.$magento.client.interceptors.response.use(
    (response) => {
      // 在这里处理响应数据
      // 例如，获取自定义响应头 x-client-country-code
      const countryCode = response.headers['x-client-country-code'];
      // const countryCode = 'MX';
      if (countryCode) {
        app.$vsf.$magento.customData = {
          countryCode, // 只是示例，可以添加其他相关数据
        };
      }

      // 返回响应以供后续处理
      return response;
    },
    (error) => {
      // 在这里处理错误
      console.error('Response error:', error);
      return Promise.reject(error); // 继续抛出错误
    }
  );
};
