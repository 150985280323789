







































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRoute,
  useRouter,
} from '@nuxtjs/composition-api';
import {
  SfBadge,
  SfButton,
  SfHeader,
  SfImage,
  SfOverlay,
} from '@storefront-ui/vue';

import SvgImage from '~/components/General/SvgImage.vue';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderLogo from '~/components/HeaderLogo.vue';
import { useUiHelpers, useUiState } from '~/composables';
import useUiNotification from '~/composables/useUiNotification';
import { addBasePath } from '~/helpers/addBasePath';
import { useUser } from '~/modules/account/composables/useUser';
import { useSidebarLinkGroups } from '~/modules/account/pages/MyAccount/useSidebarLinkGroups';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useCart } from '~/modules/checkout/composables/useCart';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';

import { useTopBar } from './TopBar/useTopBar';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SfOverlay,
    SfImage,
    MobileCategorySidebar: () =>
      import(
        '~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue'
      ),
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    // StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    // SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () =>
      import('~/components/Header/SearchBar/SearchResults.vue'),
    CartProducts: () => import('./CartProducts.vue'),
    SelectWebsitePop: () => import('~/components/SelectWebsitePop.vue'),
    /* webpackPrefetch: true */
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      app,
      localePath,
      app: { i18n },
      $config: { currentSite },
    } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const categories = useCategoryStore();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const isShowUserHover = ref<boolean>(false);
    const isShowCartHover = ref<boolean>(false);
    const timeoutID = ref(null);
    const timeoutCartID = ref(null);
    const isOpenSelectWebsitePop = ref<Boolean>(false);
    const {
      cart,
      loadTotalQty,
      error: cartError,
      clear: clearCart,
    } = useCart();
    const { loadItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();
    const { send: sendNotification } = useUiNotification();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const { logoutUser } = useSidebarLinkGroups();
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const goToWish = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'wish' }));
      } else {
        sendNotification({
          id: Symbol('login_view_wish'),
          message: i18n.t('Must be logged in to view your wish list') as string,
          persist: false,
          type: 'info',
          icon: 'info',
        });
        await router.push(localePath('/user/signin'));
      }
    };

    const handleHoverIn = () => {
      isShowUserHover.value = true;
      if (timeoutID.value) {
        clearTimeout(timeoutID.value);
      }
    };
    const handleHoverLeave = () => {
      timeoutID.value = setTimeout(() => {
        isShowUserHover.value = false;
      }, 1000);
    };
    const handleCartHoverIn = () => {
      isShowCartHover.value = true;
      if (timeoutCartID.value) {
        clearTimeout(timeoutCartID.value);
      }
    };
    const handleCartHoverLeave = () => {
      timeoutCartID.value = setTimeout(() => {
        isShowCartHover.value = false;
      }, 500);
    };
    const categoryTree = ref<CategoryTree[]>([]);
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'account-my-overview' }));
      } else {
        await router.push(localePath('/user/signin'));
      }
    };

    const handleRoute = async (pathStr) => {
      await router.push(localePath(pathStr));
    };

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    const fetch = async () => {
      if (
        !categories.globalCateTree ||
        !categoryList ||
        categoryList?.value?.length === 0
      ) {
        await categoriesListLoad({ pageSize: 20 });
      }
      categoryTree.value = categories.globalCateTree[0]?.children.filter(
        (category) => category.include_in_menu
      );
      if (categoryTree?.value?.length) {
        // add Brand
        categoryTree.value.push({
          children: [],
          level: 2,
          name: app.i18n.t('Brand'),
          url_path: 'brand',
          url_suffix: '',
        } as CategoryTree);
      }
    };

    const loadCategoryMenu = async () => {
      if (categories.categories === null) {
        await categories.load();
      }
    };
    const loadTrackingScript = () => {
      const gbScript = [
        'https://www.dwin1.com/5350.js', // awin-track
        'https://s.kk-resources.com/leadtag.js', // kelkoo-tracking
        'https://hm.baidu.com/hm.js?a26516ae6e50505a6a08aa3b19d30b14', // UK-Baidu
      ];
      gbScript.forEach((src) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => {
          console.log(`${src} loaded successfully`);
        };
        document.head.appendChild(script);
      });
    };
    const loadClarityScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "ncsjlszoxq");
      `;

      document.head.appendChild(script);
    };
    onMounted(async () => {
      if (process.client) {
        if (isAuthenticated.value && (!user.value || !user.value.firstname)) {
          await loadUser();
        }
        if (isAuthenticated.value) {
          await loadItemsCount();
        }
        if (app.$device.isDesktop) {
          await fetch();
        } else {
          await loadCategoryMenu();
        }
        const { path } = route.value;
        if (path.indexOf('thank-you') < 0) {
          // thank you page no need to fetch
          await loadTotalQty(); // cart qty
        }
        if (cartError.value.loadTotalQty) {
          await clearCart({ customQuery: { cart: 'cart' }, customHeaders: {} });
        }
        // install zenDesk
        const currentLocale = app.i18n.locale || 'gb';
        if (
          currentLocale !== 'cn' &&
          path.indexOf('/cart') < 0 &&
          path.indexOf('/signin') < 0
        ) {
          const scriptZenDesk = document.createElement('script');
          scriptZenDesk.type = 'text/javascript';
          scriptZenDesk.id = 'ze-snippet';
          scriptZenDesk.src =
            'https://static.zdassets.com/ekr/snippet.js?key=92797b58-e4d5-42b4-8ea2-e1b13132b1fc';
          scriptZenDesk.defer = true;
          document.getElementsByTagName('body')[0].appendChild(scriptZenDesk);
        }
        if (currentLocale === 'gb') {
          loadTrackingScript();
          loadClarityScript();
        }
      }
    });

    const toggleSearchSidebar = (newVal) => {
      if (newVal === false && isSearchOpen.value === false) return;
      isSearchOpen.value = !isSearchOpen.value;
    };
    const handleToHome = () => {
      if (window) {
        window.location.href = '/';
      }
    };
    const handleOpenSelectWebsiteDialog = () => {
      isOpenSelectWebsitePop.value = true;
    };
    const close = () => {
      isOpenSelectWebsitePop.value = false;
    };
    const closeWebsitedialog = () => {
      isOpenSelectWebsitePop.value = false;
    };

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      goToWish,
      goToCart,
      user,
      toggleSearchSidebar,
      isShowUserHover,
      isShowCartHover,
      handleHoverIn,
      handleHoverLeave,
      handleRoute,
      logoutUser,
      loadCategoryMenu,
      isMobileMenuOpen,
      toggleMobileMenu,
      handleCartHoverIn,
      handleCartHoverLeave,
      addBasePath,
      currentSite,
      handleToHome,
      isOpenSelectWebsitePop,
      handleOpenSelectWebsiteDialog,
      close,
      closeWebsitedialog,
    };
  },
  head() {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
    const currentLocale = this.$i18n.locale;
    // const routePath = this.$route?.path;
    // console.log('current path:', routePath);
    const gbScript = [
      // {
      //   hid: 'awin-track',
      //   id: 'awin-track',
      //   src: 'https://www.dwin1.com/5350.js',
      //   defer: true,
      //   type: 'text/javascript',
      //   charset: 'utf-8',
      // },
      // {
      //   hid: 'uk-kelkoo-tracking',
      //   id: 'uk-kelkoo-tracking',
      //   src: 'https://s.kk-resources.com/leadtag.js',
      //   defer: true,
      //   type: 'text/javascript',
      //   charset: 'utf-8',
      // },
      // {
      //   hid: 'UK-Baidu-Statistics',
      //   id: 'UK-Baidu-Statistics',
      //   src: 'https://hm.baidu.com/hm.js?a26516ae6e50505a6a08aa3b19d30b14',
      //   defer: true,
      //   type: 'text/javascript',
      //   charset: 'utf-8',
      // },
      // {
      //   id: 'Microsoft-Clarity-Script',
      //   defer: true,
      //   async: true,
      //   type: 'text/javascript',
      //   charset: 'utf-8',
      //   innerHTML: `
      //     (function(c,l,a,r,i,t,y){
      //         c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      //         t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      //         y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      //     })(window, document, "clarity", "script", "ncsjlszoxq");
      //   `,
      // },
    ];
    const usScript = [
      {
        hid: 'US-Baidu-Statistics',
        id: 'US-Baidu-Statistics',
        src: 'https://hm.baidu.com/hm.js?4642875e00813ca0cb35226ab637238b',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        id: 'US-Pinterest-tag',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          <!-- Pinterest Tag -->
            !function(e){if(!window.pintrk){window.pintrk = function () {
              window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
                n=window.pintrk;n.queue=[],n.version="3.0";var
                t=document.createElement("script");t.async=!0,t.src=e;var
                r=document.getElementsByTagName("script")[0];
                r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
              pintrk('load', '2613925467137', {em: '<user_email_address>'});
              pintrk('page');
            var img = document.createElement("img");
            img.src = "https://ct.pinterest.com/v3/?event=init&tid=2613925467137&pd[em]=<hashed_email_address>&noscript=1";
            img.setAttribute("height", "1");
            img.setAttribute("width", "1");
            img.setAttribute("alt", "");
            img.style.display = "none";
            document.addEventListener("DOMContentLoaded", function() {
              document.body.appendChild(img);
             });
            <!-- end Pinterest Tag -->`,
      },
    ];
    const cnScript = [
      // {
      //   id: 'Rakten-Marketing-Tracking',
      //   defer: true,
      //   type: 'text/javascript',
      //   charset: 'utf-8',
      //   innerHTML: `
      //     (function (url) {
      //         /*Tracking Bootstrap
      //         Set Up DataLayer objects/properties here*/
      //         if(!window.DataLayer){
      //             window.DataLayer = {};
      //         }
      //         if(!DataLayer.events){
      //             DataLayer.events = {};
      //         }
      //         DataLayer.events.SiteSection = "1";

      //         var loc, ct = document.createElement("script");
      //         ct.type = "text/javascript";
      //         ct.async = true;
      //         ct.src = url;
      //         loc = document.getElementsByTagName('script')[0];
      //         loc.parentNode.insertBefore(ct, loc);
      //     }(document.location.protocol + "//intljs.rmtag.com/114906.ct.js"));
      //   `,
      // },
      {
        hid: 'awin-track',
        id: 'awin-track',
        src: 'https://www.dwin1.com/5350.js',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        id: 'CN-Baidu-Statistics',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.async = true;
            hm.src = "https://hm.baidu.com/hm.js?57dfcfdbea5be460f02efc02cc5ef9d8";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();`,
      },
    ];
    const scripts = {
      gb: gbScript,
      us: usScript,
      cn: cnScript,
    };

    const gbLink = [
      {
        rel: 'dns-prefetch',
        href: 'https://www.unineed.com',
      },
      {
        rel: 'preconnect',
        href: 'https://www.haitaoweb.com',
      },
      {
        rel: 'preconnect',
        href: 'https://b.clarity.ms',
      },
      {
        rel: 'preconnect',
        href: 'https://www.clarity.ms',
      },
      {
        rel: 'preconnect',
        href: 'https://lantern.roeye.com',
      },
      {
        rel: 'preconnect',
        href: 'https://www.googletagmanager.com',
      },
      {
        rel: 'preconnect',
        href: 'https://lantern.roeyecdn.com',
      },
      {
        rel: 'preconnect',
        href: 'https://ekr.zdassets.com',
      },
      {
        rel: 'preconnect',
        href: 'https://td.doubleclick.net',
      },
      {
        rel: 'preconnect',
        href: 'https://smct.co',
      },
      {
        rel: 'preconnect',
        href: 'https://www.google-analytics.com',
      },
    ];
    const usLink = [
      {
        rel: 'dns-prefetch',
        href: 'https://us.unineed.com',
      },
      {
        rel: 'preconnect',
        href: 'https://www.haitaoweb.com',
      },
    ];
    const cnLink = [
      {
        rel: 'dns-prefetch',
        href: 'https://cn.unineed.com',
      },
      {
        rel: 'preconnect',
        href: 'https://cn.haitaoweb.com',
      },
    ];
    const mexLink = [
      {
        rel: 'dns-prefetch',
        href: 'https://mx.unineed.com',
      },
      {
        rel: 'preconnect',
        href: 'https://www.haitaoweb.com',
      },
    ];
    const links = {
      gb: gbLink,
      us: usLink,
      cn: cnLink,
      mx: mexLink,
    };

    const link = links[currentLocale] || [];
    const script = scripts[currentLocale] || [];
    if (
      process.client &&
      process.env.NODE_ENV !== 'development' &&
      currentLocale !== 'cn' &&
      !window.dataLayer
    ) {
      console.log('** GTM installed **');
      script.push({
        hid: 'Google-Tag-Manager',
        id: 'Google-Tag-Manager',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PSXRM6K9');
        document.addEventListener("DOMContentLoaded", function() {
            var iframe = document.createElement("iframe");
            iframe.src="https://www.googletagmanager.com/ns.html?id=GTM-PSXRM6K9";
            iframe.width = "0";
            iframe.height = "0";
            iframe.display = "none";
            iframe.style.visibility = "hidden";
            document.body.appendChild(iframe);
            console.log('** GTM installed **');
        });
        `,
      });
      script.push({
        hid: 'Google-Tag-Manager-New',
        id: 'Google-Tag-Manager-New',
        async: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-5S7SXNBC');
        `,
      });
    }

    const enMeta = [
      {
        name: 'google-site-verification',
        content: '45B8X3QXx__9LzOm4szVMBO-b97h_WFLziKQ0UpAXmA',
      },
    ];
    const mxMeta = [
      {
        name: 'google-site-verification',
        content: 'eD_FuY1n2o61SowWUCKUO69MZz1flEFCf-_cssmj8oY',
      },
    ];

    const metas = {
      gb: enMeta,
      us: enMeta,
      cn: [],
      mex: mxMeta,
    };
    const meta = metas[currentLocale] || [];
    return {
      script,
      meta,
      link,
    };
  },
});
